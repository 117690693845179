import React, { useEffect, useState } from "react";
import { Checkbox, Input, Select, Tag, Tabs } from "antd";
import { TableGrid } from "../../Component/Table";
import "antd/dist/antd.min.css";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import moment from "moment";
import { CSVLink } from "react-csv";
import { StyleToPRint } from "../../Component/jsonsStyles/styleJson";
import { useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Table, Dropdown, Menu, Modal, Button, message } from "antd";
import axios from "axios";
import en from "../../localization/en";
import ar from "../../localization/ar";

const CheckboxGroup = Checkbox.Group;

export const ComplaintAndSuggestions = () => {
  const pdfRef = React.createRef();
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TotalCount, setTotalCount] = useState(30);
  const [tabkey, settabkey] = useState("1");
  const [selectedRecord, setSelectedRecord] = useState(null);
  // Ensure this is a string

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const open = t.Open;
  const closed = t.Closed;
  const inProgress = t.InProgress;

  const columns = [
    {
      key: "Id",
      title: <p className="table-header-text">{t.id} </p>,
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text">{t.name} </p>,
      dataIndex: "title",
      className: "no-wrap",
    },
    {
      title: <p className="table-header-text"> {t.Phonenumber}</p>,
      dataIndex: "phone",
    },
    {
      title: <p className="table-header-text"> {t.dateMessage}</p>,
      dataIndex: "CreationDate",
      className: "no-wrap",
      render: (_, record) => {
        return <p> {moment(record?.CreationDate)?.format("YYYY-MM-DD ")} </p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        if (record?.key == 3) {
          return (
            <Dropdown
              overlay={
                <Menu onClick={() => handleMenuClick(record)}>
                  <Menu.Item key="1">Update Status</Menu.Item>
                </Menu>
              }
              trigger={["hover"]}
            >
              <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
            </Dropdown>
          );
        }
      },
    },
  ];
  const handleMenuClick = (record) => {
    console.log("record", record);

    setSelectedRecord(record);
    showModal(record);
  };

  const showModal = (record) => {
    Modal.confirm({
      title: "Are you sure you want to update the status?",
      content:
        record?.statusId == 1
          ? "Updating status to InProgress"
          : "Updating status to Closed",
      okText: "Yes",
      cancelText: "No",
      cancelButtonProps: {
        style: { color: "black" }, // Setting the text color to black
      },
      onOk: () => handleUpdateStatus(record),
    });
  };

  const handleUpdateStatus = async (record) => {
    setLoading(true);

    let statusChangeID;
    if (record?.statusId == 1) {
      statusChangeID = 2;
    } else if (record?.statusId == 2) {
      statusChangeID = 3;
    }

    try {
      // Replace with your API endpoint
      const response = await Api.get(
        `AdminAPIs/UpdateComplaintsAndSuggestions?ticketId=${record?.key}&statusId=${statusChangeID}`
      );
      fetchListData();
      message.success("Status updated successfully!");
    } catch (error) {
      message.error("Failed to update status.");
    } finally {
      setLoading(false);
    }
  };

  const fetchListData = (index = 0) => {
    setLoading(true);
    Api.get(`AdminAPIs/GetComplaintsAndSuggestions?Page=${index}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setCallBack(res?.data?.model);
          setCallBackList(
            res?.data?.model?.map((item) => {
              return {
                key: item.Id,
                title: currentLang === "en" ? item.Name : item.NameLT,
                phone: item?.PhoneNumber && (
                  <a href={`tel:${item?.PhoneKey + item?.PhoneNumber}`}>
                    {item?.PhoneKey + item?.PhoneNumber}{" "}
                  </a>
                ),
                Message: item.Message,
                CreationDate: item.CreationDate,
                statusId: item.StatusId,
              };
            })
          );
          if (res?.data?.metas?.TotalaCount) {
            setTotalCount(res?.data?.metas?.TotalaCount);
          }
        }
      },

      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchListData();
  }, []);

  const onChange = (key) => {
    console.log("Active Tab Key: ", key, typeof key);
    settabkey(key);
  };

  const items = [
    {
      key: "1",
      label: open,
      children: (
        <TableGrid
          TableRef={pdfRef}
          columns={columns || []}
          dataSource={callBackList.filter((list) => list.statusId == 1) || []}
          loading={loading}
          pageSize={30}
          total={TotalCount}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <h3 style={{ fontWeight: "bold" }}>
                  {record?.title}'s Complaint,
                </h3>
                <p>{record.Message}</p>
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
          onChange={(pagination) => {
            fetchListData(pagination?.current - 1);
          }}
        />
      ),
    },
    {
      key: "2",
      label: inProgress,
      children: (
        <TableGrid
          TableRef={pdfRef}
          columns={columns || []}
          dataSource={callBackList.filter((list) => list.statusId == 2) || []}
          loading={loading}
          pageSize={30}
          total={TotalCount}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <h3 style={{ fontWeight: "bold" }}>
                  {record?.title}'s Complaint,
                </h3>
                <p>{record.Message}</p>
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
          onChange={(pagination) => {
            fetchListData(pagination?.current - 1);
          }}
        />
      ),
    },
    {
      key: "3",
      label: closed,
      children: (
        <TableGrid
          TableRef={pdfRef}
          columns={columns?.filter((col) => col.key !== "action") || []}
          dataSource={callBackList.filter((list) => list.statusId == 3) || []}
          loading={loading}
          pageSize={30}
          total={TotalCount}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <h3 style={{ fontWeight: "bold" }}>
                  {record?.title}'s Complaint,
                </h3>
                <p>{record.Message}</p>
              </div>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
          onChange={(pagination) => {
            fetchListData(pagination?.current - 1);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="table__filter">
        <p className="table__title">{t.ComplaintAndSuggestions}</p>
      </div>

      <Tabs
        defaultActiveKey="1"
        activeKey={tabkey}
        onChange={onChange}
        items={items}
      />
    </>
  );
};
